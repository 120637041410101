class MobileMenu {

    constructor(toggleBtn, menuItem , subMenu, navSelector) {

        this.menu = document.querySelector(navSelector);
        this.toggleBtn = document.querySelector(toggleBtn);
        this.menuItem = this.menu.querySelectorAll(menuItem);

    }

    toggleMenu() {

        this.toggleBtn.addEventListener('click', event => {

            this.menu.style.transition = "transform .5s"
            this.toggleBtn.classList.toggle('is-active');
            this.menu.classList.toggle('header-nav_active');

            this.closeSubMenus()
        })
    }

    openSubMenu() {

        this.menuItem.forEach((item) => {

            if(item.nextElementSibling) {

                let subMenu = item.nextElementSibling;
                // let subMenu = item.parentNode.querySelector('.menu_level-1');
                item.addEventListener('click', event => {

                console.log(subMenu.style.height);
                    if(subMenu.querySelector('.menu__item')) {
                        event.preventDefault();
                    };

                    if(subMenu.style.height == 0 || subMenu.style.height == "0px") {

                        this.closeSubMenus();
                        subMenu.style.height = subMenu.scrollHeight + "px";
                        // subMenu.style.paddingBottom = "10px";

                    } else {

                        subMenu.removeAttribute("style");

                    }
                }
                )
            }
        })
    }

    closeSubMenus(subMenuItem) {

        this.menuItem.forEach((item) => {
            
            let subMenu;

            if(item.nextElementSibling) {

                subMenu = item.nextElementSibling;
                subMenu.removeAttribute("style");

            }
        })
    }

    destroyMenu() {

    }

}

export default MobileMenu
// let mobile_menu = new mobileMenu('.hamburger-box', '.menu_level-0');
