import MobileMenu from './mobile-menu.js';
import { AccessibilityToggler } from '@websm/accessibility';

let accessibilityButtons = document.querySelectorAll('.accessibility');
accessibilityButtons.forEach(el => new AccessibilityToggler(el));

let mobile_menu = new MobileMenu('.hamburger', '.menu__link_level-0', '.menu_level-1', '.header-nav');

mobile_menu.toggleMenu();

let resCheck = false;

if(window.innerWidth <= 876 || "ontouchstart" in document.documentElement) {
    resCheck = true;
};

let openSubDup = mobile_menu.openSubMenu;

if(resCheck) {
    mobile_menu.openSubMenu();
}


window.addEventListener('resize', () => {

    if(window.innerWidth <= 876 || "ontouchstart" in document.documentElement) {
        if(!resCheck) {
            mobile_menu.openSubMenu();
        }
            resCheck = true;
        // mobile_menu.openSubMenu();
    } else {

        // if(resCheck){
            // mobile_menu.openSubMenu = null;
        // resCheck = false;
        // }
    }
console.log(resCheck);
});


// disable hover on links with no submenus
let hoverLink = document.querySelectorAll('.menu__item_level-0');

hoverLink.forEach((link) => {

    if (!link.querySelector('.menu_level-1')) {
        link.classList.add('no-hover-before')
    }
})


